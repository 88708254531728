<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ProfileNavigation />
                </b-col>
                    <b-col cols="12" md="8" lg="9">
                        <div v-if="!jobLoaded" class="sector pl-0 pr-0">
                            <Spinner size="medium" line-fg-color="#e91e63" />
                        </div>
                        <div v-else class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">Edit job offer</h2>
                            <div class="profile__content">
                                <p v-if="updateJobForm.localError" class="custom-error mb-3">{{ updateJobForm.localError }}</p>
                                <p v-if="getJobError" class="custom-error mb-3"> {{ getJobError }} </p>
                                <form :class="getDisabledJob ? 'disabled-element' : ''" @submit.prevent="updateJob" autocomplete="off">
                                    <b-row>
                                        <b-col cols="12">
                                            <span class="profile__label float-right">*Required fields</span>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Job position*</label>
                                                <input :disabled="getDisabledJob" v-model.trim="updateJobForm.jobPosition" @input="$v.updateJobForm.jobPosition.$touch()" :class="[{'input-error' : $v.updateJobForm.jobPosition.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type job position" autocomplete="off" />
                                                <div v-if="$v.updateJobForm.jobPosition.$error">
                                                    <p v-if="!$v.updateJobForm.jobPosition.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobPosition.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Company name*</label>
                                                <input :disabled="getDisabledJob" v-model.trim="updateJobForm.jobCompany" @input="$v.updateJobForm.jobCompany.$touch()" :class="[{'input-error' : $v.updateJobForm.jobCompany.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company name" autocomplete="off" />
                                                <div v-if="$v.updateJobForm.jobCompany.$error">
                                                    <p v-if="!$v.updateJobForm.jobCompany.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobCompany.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">E-mail address</label>
                                                <input :disabled="getDisabledJob" v-model.trim="updateJobForm.jobContactEmail" @input="$v.updateJobForm.jobContactEmail.$touch()" :class="[{'input-error' : $v.updateJobForm.jobContactEmail.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company e-mail" type="email" autocomplete="off" />
                                                <div v-if="$v.updateJobForm.jobContactEmail.$error">
                                                    <p v-if="!$v.updateJobForm.jobContactEmail.email" class="custom-error">E-mail address is invalid</p>
                                                    <p v-else-if="!$v.updateJobForm.jobContactEmail.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">City*</label>
                                                <input :disabled="getDisabledJob" v-model.trim="updateJobForm.jobCity" @input="$v.updateJobForm.jobCity.$touch()" :class="[{'input-error' : $v.updateJobForm.jobCity.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type city" autocomplete="off" />
                                                <div v-if="$v.updateJobForm.jobCity.$error">
                                                    <p v-if="!$v.updateJobForm.jobCity.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobCity.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Form of employment*</label>
                                                <v-select :disabled="getDisabledJob" v-model.trim="updateJobForm.jobType" @input="$v.updateJobForm.jobType.$touch()" :class="[{'input-error' : $v.updateJobForm.jobType.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="custom-sorter mb-1" labelTitle = "Select form of employment" :options="jobTypes" />
                                                <div v-if="$v.updateJobForm.jobType.$error">
                                                    <p v-if="!$v.updateJobForm.jobType.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobType.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Phone number</label>
                                                <input :disabled="getDisabledJob" v-model.trim="updateJobForm.jobContactPhone" @input="$v.updateJobForm.jobContactPhone.$touch()" :class="[{'input-error' : $v.updateJobForm.jobContactPhone.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type contact phone number" autocomplete="off" />
                                                <div v-if="$v.updateJobForm.jobContactPhone.$error">
                                                    <p v-if="!$v.updateJobForm.jobContactPhone.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Company website URL<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />With http or https protocol</span></label>
                                                <input :disabled="getDisabledJob" v-model.trim="updateJobForm.jobContactUrl" @input="$v.updateJobForm.jobContactUrl.$touch()" :class="[{'input-error' : $v.updateJobForm.jobContactUrl.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company website URL" autocomplete="off" />
                                                <div v-if="$v.updateJobForm.jobContactUrl.$error">
                                                    <p v-if="!$v.updateJobForm.jobContactUrl.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                    <p v-else-if="!$v.updateJobForm.jobContactUrl.urlCustomValidator" class="custom-error">This field must start with either http:// or https://</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Country*</label>
                                                <v-select :disabled="getDisabledJob" v-model.trim="updateJobForm.jobCountry" @input="$v.updateJobForm.jobCountry.$touch()" :class="[{'input-error' : $v.updateJobForm.jobCountry.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="custom-sorter mb-1" labelTitle = "Select country" :options="countries" :searchable = true />
                                                <div v-if="$v.updateJobForm.jobCountry.$error">
                                                    <p v-if="!$v.updateJobForm.jobCountry.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobCountry.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Application method*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Determine how candidates can apply for this position.</span></label>
                                                <div>
                                                    <span @click="resetApplyMethod"><p-radio class="pretty p-default p-curve p-smooth" name="radio" color="success" v-model="updateJobForm.applyMethod" value="email">E-mail</p-radio></span>
                                                    <span @click="resetApplyMethod"><p-radio class="pretty p-default p-curve p-smooth" name="radio" color="success" v-model="updateJobForm.applyMethod" value="url">URL</p-radio></span>
                                                    <div v-if="$v.updateJobForm.applyMethod.$error">
                                                        <p v-if="!$v.updateJobForm.applyMethod.required" class="custom-error">This field is required</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col v-if="updateJobForm.applyMethod == 'email'" cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Apply e-mail address*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />e-mail address that candidate can use to apply for this position.</span></label>
                                                <input :disabled="getDisabledJob" v-model.trim="updateJobForm.jobApply" @input="$v.updateJobForm.jobApply.$touch()" :class="[{'input-error' : $v.updateJobForm.jobApply.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type apply e-mail address" autocomplete="off" />
                                                <div v-if="$v.updateJobForm.jobApply.$error">
                                                    <p v-if="!$v.updateJobForm.jobApply.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobApply.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                                <p v-if="invalidEmail" class="custom-error">E-mail address is invalid</p>
                                            </div>
                                        </b-col>
                                        <b-col v-if="updateJobForm.applyMethod == 'url'" cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Apply URL*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Link URL to form or website where candidate can apply for this position. With http or https protocol</span></label>
                                                <input :disabled="getDisabledJob" v-model.trim="updateJobForm.jobApply" @input="$v.updateJobForm.jobApply.$touch()" :class="[{'input-error' : $v.updateJobForm.jobApply.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type url" autocomplete="off" />
                                                <div v-if="$v.updateJobForm.jobApply.$error">
                                                    <p v-if="!$v.updateJobForm.jobApply.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobApply.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                                <p v-if="invalidUrl" class="custom-error">This field must start with either http:// or https://</p>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Job description*</label>
                                                <vue-editor :disabled="getDisabledJob" v-model.trim="updateJobForm.jobDescription" @input="$v.updateJobForm.jobDescription.$touch()" :class="[{'input-error' : $v.updateJobForm.jobDescription.$error }, getDisabledJob ? 'disabled-element' : '' ]" :editorToolbar="customToolbar" placeholder="Type job description"></vue-editor>
                                                <div v-if="$v.updateJobForm.jobDescription.$error">
                                                    <p v-if="!$v.updateJobForm.jobDescription.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobDescription.maxLength" class="custom-error">This field must not exceed 3000 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Qualifications and skills*</label>
                                                <vue-editor :disabled="getDisabledJob" v-model.trim="updateJobForm.jobQualifications" @input="$v.updateJobForm.jobQualifications.$touch()" :class="[{'input-error' : $v.updateJobForm.jobQualifications.$error }, getDisabledJob ? 'disabled-element' : '' ]" :editorToolbar="customToolbar" placeholder="Type job qualifications and skills"></vue-editor>
                                                <div v-if="$v.updateJobForm.jobQualifications.$error">
                                                    <p v-if="!$v.updateJobForm.jobQualifications.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobQualifications.editorCustomValidator" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobQualifications.maxLength" class="custom-error">This field must not exceed 2000 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Offer and benefit*</label>
                                                <vue-editor :disabled="getDisabledJob" v-model.trim="updateJobForm.jobOffer" @input="$v.updateJobForm.jobOffer.$touch()" :class="[{'input-error' : $v.updateJobForm.jobOffer.$error }, getDisabledJob ? 'disabled-element' : '' ]" :editorToolbar="customToolbar" placeholder="Type job offer and benefits"></vue-editor>
                                                <div v-if="$v.updateJobForm.jobOffer.$error">
                                                    <p v-if="!$v.updateJobForm.jobOffer.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobOffer.editorCustomValidator" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateJobForm.jobOffer.maxLength" class="custom-error">This field must not exceed 2000 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <button :disabled="getDisabledJob" type="submit" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Update job offer</button>
                                        </b-col>
                                    </b-row>
                                </form>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import SimpleLineIcons from 'vue-simple-line'
import VSelect from '../assets/files/customSelect.esm.js'
import { VueEditor, Quill } from 'vue2-editor'
import countriesList from '../assets/files/countriesList.js'
import jobFormTypes from '../assets/files/jobFormTypes.js'
import { mapGetters } from 'vuex'
import router from '../router/router'
import firebase from 'firebase/app'
import Spinner from 'vue-simple-spinner'
import PrettyRadio from 'pretty-checkbox-vue/radio'
import {
  email,
  required,
  maxLength
} from 'vuelidate/lib/validators'

function editorCustomValidator (value) {
  return value !== '<ul><li><br></li></ul>'
}

function urlCustomValidator (value) {
  if (value !== '') {
    var pattern = /^((http|https):\/\/)/

    if (!pattern.test(value)) {
      return false
    }
  }
  return true
}

export default {
  components: {
    ProfileNavigation,
    SimpleLineIcons,
    VSelect,
    VueEditor,
    Spinner,
    'p-radio': PrettyRadio
  },
  data: function () {
    return {
      jobLoaded: false,
      customToolbar: [
        ['bold', 'italic', 'underline', 'link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean']
      ],
      jobTypes: [],
      countries: [],
      updateJobForm: {
        jobPosition: null,
        jobType: null,
        jobCompany: null,
        jobContactEmail: '',
        jobContactPhone: '',
        jobContactUrl: '',
        jobCity: null,
        jobCountry: null,
        jobApply: null,
        jobDescription: null,
        jobQualifications: '<ul></ul>',
        jobOffer: '<ul></ul>',
        localError: null,
        jobId: null,
        applyMethod: null
      },
      invalidEmail: false,
      invalidUrl: false
    }
  },

  validations: {
    updateJobForm: {
      jobPosition: {
        required: required,
        maxLength: maxLength(200)
      },
      jobType: {
        required: required,
        maxLength: maxLength(200)
      },
      jobCompany: {
        required: required,
        maxLength: maxLength(200)
      },
      jobContactEmail: {
        email: email,
        maxLength: maxLength(200)
      },
      jobContactPhone: {
        maxLength: maxLength(20)
      },
      jobContactUrl: {
        maxLength: maxLength(200),
        urlCustomValidator
      },
      jobCity: {
        required: required,
        maxLength: maxLength(200)
      },
      jobCountry: {
        required: required,
        maxLength: maxLength(200)
      },
      jobApply: {
        required: required,
        maxLength: maxLength(200)
      },
      jobDescription: {
        required: required,
        maxLength: maxLength(3000)
      },
      jobQualifications: {
        required: required,
        maxLength: maxLength(2000),
        editorCustomValidator
      },
      jobOffer: {
        required: required,
        maxLength: maxLength(2000),
        editorCustomValidator
      },
      applyMethod: {
        required: required
      }
    }
  },
  methods: {
    updateJob () {
      if (this.updateJobForm.applyMethod == 'email') {
        if (this.validateEmail(this.updateJobForm.jobApply)) {
          this.invalidEmail = false

          this.$store.dispatch('setDisabledJob')
          this.$v.updateJobForm.$touch()
          if (!this.$v.updateJobForm.$invalid) {
            this.$store.dispatch('updateJob', { jobId: this.updateJobForm.jobId, position: this.updateJobForm.jobPosition, type: this.updateJobForm.jobType, company: this.updateJobForm.jobCompany, email: this.updateJobForm.jobContactEmail, phone: this.updateJobForm.jobContactPhone, website: this.updateJobForm.jobContactUrl, city: this.updateJobForm.jobCity, country: this.updateJobForm.jobCountry, apply: this.updateJobForm.jobApply, desc: this.updateJobForm.jobDescription, qual: this.updateJobForm.jobQualifications, offer: this.updateJobForm.jobOffer, method: this.updateJobForm.applyMethod })
              .then(() => {
                this.updateJobForm.localError = ''
                this.scrollToTop(500)
              })
              .catch(error => {
                this.updateJobForm.localError = error.message
                this.$store.dispatch('clearDisabledJob')
              })
          } else {
            this.scrollToTop(500)
            this.updateJobForm.localError = 'Invalid form'
            this.$store.dispatch('clearDisabledJob')
          }
        } else {
          this.invalidEmail = true
          this.$v.updateJobForm.$touch()
          this.scrollToTop(500)
          this.updateJobForm.localError = 'Invalid form'
          this.$store.dispatch('clearDisabledJob')
        }
      } else {
        if (this.validateUrl(this.updateJobForm.jobApply)) {
          this.invalidUrl = false

          this.$store.dispatch('setDisabledJob')
          this.$v.updateJobForm.$touch()
          if (!this.$v.updateJobForm.$invalid) {
            this.$store.dispatch('updateJob', { jobId: this.updateJobForm.jobId, position: this.updateJobForm.jobPosition, type: this.updateJobForm.jobType, company: this.updateJobForm.jobCompany, email: this.updateJobForm.jobContactEmail, phone: this.updateJobForm.jobContactPhone, website: this.updateJobForm.jobContactUrl, city: this.updateJobForm.jobCity, country: this.updateJobForm.jobCountry, apply: this.updateJobForm.jobApply, desc: this.updateJobForm.jobDescription, qual: this.updateJobForm.jobQualifications, offer: this.updateJobForm.jobOffer, method: this.updateJobForm.applyMethod })
              .then(() => {
                this.updateJobForm.localError = ''
                this.scrollToTop(500)
              })
              .catch(error => {
                this.updateJobForm.localError = error.message
                this.$store.dispatch('clearDisabledJob')
              })
          } else {
            this.scrollToTop(500)
            this.updateJobForm.localError = 'Invalid form'
            this.$store.dispatch('clearDisabledJob')
          }
        } else {
          this.invalidUrl = true
          this.$v.updateJobForm.$touch()
          this.scrollToTop(500)
          this.updateJobForm.localError = 'Invalid form'
          this.$store.dispatch('clearDisabledJob')
        }
      }
    },
    scrollToTop (scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15)
      var scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep)
        } else clearInterval(scrollInterval)
      }, 15)
    },
    validateEmail (email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    validateUrl (url) {
      var pattern = /^((http|https):\/\/)/

      if (!pattern.test(url)) {
        return false
      } else {
        return true
      }
    },
    resetApplyMethod () {
      this.updateJobForm.jobApply = ''
    }
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabledJob')
    this.$store.dispatch('getUserJobs')
  },
  created () {
    this.countries = countriesList
    this.jobTypes = jobFormTypes

    var user = firebase.auth().currentUser

    var jobsRef = firebase.database().ref('jobs')

    jobsRef.child(this.$route.params.id).on('value', snapshot => {
      (this.updateJobForm.jobId = snapshot.key),
      (this.updateJobForm.jobPosition = snapshot.val().position),
      (this.updateJobForm.jobType = snapshot.val().type),
      (this.updateJobForm.jobCompany = snapshot.val().company),
      (this.updateJobForm.jobContactEmail = snapshot.val().email),
      (this.updateJobForm.jobContactPhone = snapshot.val().phone),
      (this.updateJobForm.jobContactUrl = snapshot.val().website),
      (this.updateJobForm.jobCity = snapshot.val().city),
      (this.updateJobForm.jobCountry = snapshot.val().country),
      (this.updateJobForm.jobApply = snapshot.val().apply),
      (this.updateJobForm.applyMethod = snapshot.val().method),
      (this.updateJobForm.jobDescription = snapshot.val().desc),
      (this.updateJobForm.jobQualifications = snapshot.val().qual),
      (this.updateJobForm.jobOffer = snapshot.val().offer)

      if (snapshot.val().user != user.uid) {
        router.push('/401')
      }

      this.jobLoaded = true
    })
  },
  computed: {
    ...mapGetters([
      'getJobError',
      'getDisabledJob'
    ])
  },
  watch: {
    'updateJobForm.jobApply': function () {
      if (this.updateJobForm.jobApply != '') {
        this.invalidEmail = false
        this.invalidUrl = false
      }
    }
  }
}
</script>
